<template>
  <div>
    <div class="content-filters">
      <div class="content-filter-show-for">
        <v-select v-model="sShowFor" :items="items" item-text="text" item-value="value" label="Mostrar por"
          class="global-select" color="var(--primary-color-border-menu-profile)"
          background-color="var(--primary-color-menu)" outlined></v-select>
      </div>
      <div class="content-filter-date" @click="menu = true">

      </div>
      <!-- <div class="content-filter-menu-btn">
        <v-menu bottom left attach min-width="200px" :close-on-content-click="false" offset-y rounded="lg">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="btn-filter-menu global-btn-neutral">
              <v-icon> mdi-tune-vertical </v-icon>
            </v-btn>
          </template>
          <v-card class="content-filter-menu">
            <div class="pa-5">
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <p class="title-filter">Transacción</p>
                  <div class="content-major-minor">
                    <v-checkbox v-model="bIn" value="in" label="" class="global-checkbox"
                      color="var(--primary-color-btn-primary)">
                      <template v-slot:label>
                        <span class="text-checkbox">Entradas</span>
                      </template>
                    </v-checkbox>
                    <v-checkbox v-model="bOut" value="out" label="" color="var(--primary-color-btn-primary)"
                      class="global-checkbox">
                      <template v-slot:label>
                        <span class="text-checkbox">Salidas</span>
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-menu>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      aTransition: ["in", "out"],
      itemTransition: [
        {
          text: "Entradas",
          value: "in",
        },
        {
          text: "Salidas",
          value: "out",
        },
      ],
      bIn: "in",
      bOut: "out",
      sShowFor: 1,
      items: [
        { text: "Por semana", value: 1 },
        { text: "Por meses", value: 2 },
      ],
      dates: [],
      menu: false,
    };
  },
  beforeMount() {
    this.$store.commit("setTransition", { in: "in", out: "out" });
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    onChange() {
      let sIn = this.aTransition.find((element) => element == "in");
      let sOut = this.aTransition.find((element) => element == "out");
    },
  },
  watch: {
    aTransition() {
    },
    sShowFor() {
      this.$emit("setShowFor", this.sShowFor);
    },
    bIn() {
      if (this.bIn == null) {
        this.bOut = "out";
      }
      this.$emit("setTransition", [this.bIn, this.bOut]);
    },
    bOut() {
      if (this.bOut == null) {
        this.bIn = "in";
      }
      this.$emit("setTransition", [this.bIn, this.bOut]);
    },
    dates() {
      if (this.dates.length === 0) {
      } else if (this.dates.length > 1) {
      }
    },
  },
};
</script>

<style scoped>
.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.content-filters {
  display: flex;
  width: 100%;
}

.content-filter-show-for {
  width: 443px;
  margin-right: 15px;
}

.title-filter {
  font-size: 14px;
  font-family: "pop-Bold";
}

.text-checkbox {
  font-size: 14px;
  font-family: "pop-Light";
}

.content-line-chart {
  position: relative;
}

.text-inventary {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  left: -8px;
  bottom: 0;
  top: 20px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: absolute;
  letter-spacing: 14px;
}

.text-period {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  text-align: center;
  left: 0%;
  right: 0%;
  bottom: -15px;
  position: absolute;
  letter-spacing: 14px;
}

.content-filter-date {
  width: 25%;
}

.content-filter-date .v-text-field {
  padding-top: 0px !important;
}

.content-filter-menu-btn {
  width: 50%;
  justify-content: end;
  display: flex;
}

.content-filter-menu {
  background-color: var(--primary-color-menu-filter) !important;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  overflow: hidden;
  border-radius: 10px !important;
  border: var(--primary-color-border-menu-profile) solid 1px;
}

.btn-filter-menu {
  width: 40px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-filters {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .content-filter-show-for {
    width: 100%;
    margin-right: 0px;
  }

  .p-text-title {
    text-align: center;
  }

  .content-filter-date {
    width: 100%;
  }

  .content-filter-menu-btn {
    width: 100%;
  }

  .btn-filter-menu {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-filter-show-for {
    width: 443px;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 45%;
  }

  .content-filter-menu-btn {
    width: 15%;
    justify-content: end;
    display: flex;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-filter-show-for {
    width: 443px;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 30%;
  }

  .content-filter-menu-btn {
    width: 45%;
    justify-content: end;
    display: flex;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>